.QSM {
  min-height: 500px;

  .qsm-quiz-container {
    &.quiz_theme_qsm-theme-sapience .qmn_pagination {
      @apply flex border-0 mt-s24;
    }

    &.quiz_theme_qsm-theme-sapience .qmn_btn {
      @apply rounded-br8;
    }

    .img.qsm-sapience-featured-image {
      @apply aspect-[3/2] lg:aspect-[2/3];
    }
  }

  .sapience-featured-image-container {
    @apply mt-0 mr-0;
  }

  .img.qsm-sapience-featured-image {
    @apply mr-0;
  }

  .qmn_btn,
  .qsm-quiz-container.qmn_quiz_container .qmn_btn,
  .qsm-quiz-container.qmn_quiz_container .btn {
    border-radius: 8px !important;
  }

  .qmn_quiz_container.quiz_theme_qsm-theme-sapience .mlw_previous {
    margin-right: 16px !important;
  }

  .quiz_theme_qsm-theme-sapience
    .qsm_contact_div
    input[type='checkbox']
    ~ label,
  .quiz_theme_qsm-theme-sapience .quiz_section .qsm-input-label {
    border-radius: 8px;
  }

  .qsm-quiz-container.quiz_theme_qsm-theme-sapience:has(.qmn_results_page) {
    justify-content: center;
    padding: 24px;
    text-align: center;

    .qsm-btn {
      margin-top: 24px;
    }
  }

  .qsm-theme-sapience-header {
    .sapience-progress-bar {
      top: 0;
    }

    &:empty {
      display: none;
    }
  }

  .quiz_theme_qsm-theme-sapience.sapience-feature-image-added .qsm-quiz-form {
    padding: 24px;
  }

  .quiz_theme_qsm-theme-sapience.sapience-feature-image-added
    .sapience-featured-image-container {
    display: none !important;
  }

  #mlw_error_message {
    display: none !important;
  }

  .quiz_theme_qsm-theme-sapience
    .qsm-quiz-form
    .qmn_error
    .qsm-input-label:after {
    display: none !important;
  }

  .quiz_theme_qsm-theme-sapience .qmn_error_message {
    padding: 2px !important;
    font-size: 12px !important;
    line-height: 14px !important;

    &::before {
      font-size: 12px !important;
    }
  }

  .quiz_section .qsm-inline-correct-info {
    margin-top: 0 !important;
  }

  .quick-question-res-p {
    font-size: 14px !important;
  }

  @media screen and (min-width: 650px) {
    min-height: 600px;

    .qsm-quiz-container.quiz_theme_qsm-theme-sapience.sapience-feature-image-added {
      max-width: 1200px !important;
    }

    .quiz_theme_qsm-theme-sapience.sapience-feature-image-added .qsm-quiz-form {
      padding: 24px 40px 24px 10px;
      width: 50%;
      max-height: 600px;
    }

    .quiz_theme_qsm-theme-sapience.sapience-feature-image-added
      .sapience-featured-image-container {
      margin-right: 0;
    }

    .quiz_theme_qsm-theme-sapience.sapience-feature-image-added
      .sapience-featured-image-container {
      width: calc(50% - 25px);
      display: block !important;
    }

    img.qsm-sapience-featured-image {
      aspect-ratio: 2/3;
      height: 650px;
    }

    .qsm-quiz-container.quiz_theme_qsm-theme-sapience:has(.qmn_results_page) {
      padding: 120px 40px;
      min-height: 600px;
    }

    .quiz_theme_qsm-theme-sapience.sapience-feature-image-added
      .qsm-theme-sapience-header
      .sapience-progress-bar {
      left: calc(50% - 50px);
    }

    .qsm-theme-sapience-header {
      min-height: 0;
      margin-bottom: 0;

      .sapience-timer {
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 60%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
}

.ContentBlockQuiz2 + .ContentGridCards {
  display: none;
}

.ContentBlockQuiz2:has(.qmn_results_page) + .ContentGridCards {
  display: block;
}
