.swiper-pagination {
  @apply flex gap-s16 items-center;

  .swiper-pagination-bullet {
    @apply w-s16 h-s16 rounded-full bg-light-grey;
  }

  .swiper-pagination-bullet-active {
    @apply bg-light-grey;
  }
}

.ContentGroupPeople {
  .swiper-button-disabled {
    @apply border-primary bg-transparent;

    .pgo-i-white::before {
      @apply bg-primary;
    }
  }
}
