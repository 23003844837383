// this file is to customize cta block color depending on which component it's adjacent to
$cta-block-selectors: (
  '.ContentListInfo',
  '.ContentGridVideo2',
  '.ContentGrid2Up'
);

@each $selector in $cta-block-selectors {
  #{$selector} + .CTABlock {
    @apply bg-light-orange;
  }
}
