.pgo-i {
  font-size: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pgo-i::before {
  content: '';
  display: inline-block;
  mask-size: contain;
  mask-repeat: no-repeat;
}

/* icon sizes */

.pgo-i-sm::before {
  height: 16px;
  width: 16px;
}

.pgo-i-sm-20::before {
  height: 20px;
  width: 20px;
}

.pgo-i-md::before {
  height: 24px;
  width: 24px;
}

.pgo-i-lg::before {
  height: 32px;
  width: 32px;
}

.pgo-i-xl::before {
  height: 48px;
  width: 48px;
}

.pgo-i-xxl::before {
  height: 64px;
  width: 64px;
}

/* icon colors */
.pgo-i-primary::before {
  @apply bg-primary;
}

.pgo-i-charcoal::before {
  @apply bg-charcoal;
}

.pgo-i-black::before {
  @apply bg-black;
}

.pgo-i-white::before {
  @apply bg-white;
}

.pgo-i-dark-grey::before {
  @apply bg-dark-grey;
}

.pgo-i-light-grey::before {
  @apply bg-light-grey;
}

.pgo-i-light-orange::before {
  @apply bg-light-orange;
}

.pgo-i-dark-grey::before {
  @apply bg-[#4D4639];
}

.pgo-i-yellow::before {
  @apply bg-[#EEBE42];
}

/* icon names */

.pgo-i-relume::before {
  mask-image: url('~@assets/icons/icon-relume.svg');
}

.pgo-i-star::before {
  mask-image: url('~@assets/icons/icon-star.svg');
}

.pgo-i-hamburger::before {
  mask-image: url('~@assets/icons/icon-hamburger.svg');
}

.pgo-i-arrow-left::before {
  mask-image: url('~@assets/icons/icon-arrow-l.svg');
}

.pgo-i-arrow-right::before {
  mask-image: url('~@assets/icons/icon-arrow-r.svg');
}

.pgo-i-facebook::before {
  mask-image: url('~@assets/icons/icon-social-fb.svg');
}

.pgo-i-instagram::before {
  mask-image: url('~@assets/icons/icon-social-ig.svg');
}

.pgo-i-linkedin::before {
  mask-image: url('~@assets/icons/icon-social-in.svg');
}

.pgo-i-youtube::before {
  mask-image: url('~@assets/icons/icon-social-yt.svg');
}

.pgo-i-twitter::before {
  mask-image: url('~@assets/icons/icon-social-x.svg');
}

.pgo-i-chevron-down::before {
  mask-image: url('~@assets/icons/icon-chevron-down.svg');
}

.pgo-i-chevron-right::before {
  mask-image: url('~@assets/icons/icon-chevron-right.svg');
}

.pgo-i-double-chevron::before {
  mask-image: url('~@assets/icons/icon-double-chevron.svg');
}

.pgo-i-close::before {
  mask-image: url('~@assets/icons/icon-close.svg');
}

.pgo-i-play::before {
  mask-image: url('~@assets/icons/icon-play.svg');
}

.pgo-i-play-circle::before {
  mask-image: url('~@assets/icons/icon-play-circle.svg');
}

.pgo-i-person-outline::before {
  mask-image: url('~@assets/icons/icon-person-outline.svg');
}

.pgo-i-location::before {
  mask-image: url('~@assets/icons/icon-location.svg');
}

.pgo-i-download::before {
  mask-image: url('~@assets/icons/icon-download.svg');
}

.pgo-i-search::before {
  mask-image: url('~@assets/icons/icon-search.svg');
}

.pgo-i-filter::before {
  mask-image: url('~@assets/icons/icon-filter.svg');
}

.pgo-i-fb-dark::before {
  mask-image: url('~@assets/icons/icon-fb-dark.svg');
}

.pgo-i-social-fb::before {
  mask-image: url('~@assets/icons/icon-social-fb.svg');
}

.pgo-i-ig-dark::before {
  mask-image: url('~@assets/icons/icon-ig-dark.svg');
}

.pgo-i-in-dark::before {
  mask-image: url('~@assets/icons/icon-social-in.svg');
}

.pgo-i-quote::before {
  mask-image: url('~@assets/icons/icon-quote.svg');
}

.pgo-i-calendar::before {
  mask-image: url('~@assets/icons/icon-calendar.svg');
}

.pgo-i-clock::before {
  mask-image: url('~@assets/icons/icon-clock.svg');
}

.pgo-i-email::before {
  mask-image: url('~@assets/icons/icon-email.svg');
}

.pgo-i-link::before {
  mask-image: url('~@assets/icons/icon-link.svg');
}
