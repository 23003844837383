.Countdown {
  &[data-is-countdown-active='false'] {
    @apply hidden;
  }

  &[data-is-countdown-active='true'] {
    @apply flex;
  }

  .countdown-text {
    @apply block font-sans text-lg font-bold;
  }
}
