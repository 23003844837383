:root {
  --swiper-pagination-color: #ad223b;
}

:root :where(a:where(:not(.wp-element-button))) {
  text-decoration: inherit;
}

html {
  scroll-behavior: smooth;
}

body[data-scroll-disable='true'] {
  @apply overflow-hidden;
}

.wrapper {
  @apply grid grid-cols-4 gap-y-s32 gap-x-s8 max-w-[1110px] mx-auto items-center px-s16 lg:px-s24 lg:grid-cols-12 @lg:gap-y-0 lg:gap-x-s24;
}

.content-wrapper {
  @apply @lg:min-h-[700px];
}

.is-active {
  @apply block;
}

.is-slide-active {
  @apply opacity-100 visible;
}

.is-hidden {
  @apply hidden;
}

.is-open {
  @apply rotate-180 transition-transform;
}

//overlap-settings
.is-overlap-50 {
  @apply lg:-my-[50px];

  div.acf-block-preview & {
    @apply my-0;
  }
}

@layer utilities {
  .font-thin {
    font-variation-settings: 'wght' 100;
  }

  .font-extralight {
    font-variation-settings: 'wght' 200;
  }

  .font-light {
    font-variation-settings: 'wght' 300;
  }

  .font-normal {
    font-variation-settings: 'wght' 400;
  }

  .font-medium {
    font-variation-settings: 'wght' 500;
  }

  .font-semibold {
    font-variation-settings: 'wght' 600;
  }

  .font-bold {
    font-variation-settings: 'wght' 700;
  }

  .font-extrabold {
    font-variation-settings: 'wght' 800;
  }

  .font-black {
    font-variation-settings: 'wght' 900;
  }
}

.wysiwyg {
  @apply text-charcoal;

  ul {
    @apply flex flex-col gap-y-s16 mt-s24;
  }

  ul li {
    @apply flex items-center gap-x-s16;

    &::before {
      content: url('~@assets/icons/icon-chevron-right.svg');
      height: 24px;
      width: 24px;
    }
  }

  &:last-child {
    @apply mb-0;
  }

  p:not(:last-of-type) {
    @apply mb-s24;
  }

  p:empty {
    @apply hidden;
  }

  ol li {
    &::before {
      content: counter(list-item) '. ';
    }
  }

  a {
    @apply text-primary underline;
  }

  h1 {
    @apply block mb-s8 font-sans2 text-h1;
  }

  h2 {
    @apply block mb-s8 font-sans2 text-h2;
  }

  h3 {
    @apply block mb-s8 font-sans2 text-h3;
  }

  h4 {
    @apply block mb-s8 font-sans2 text-h4;
  }

  h5 {
    @apply block mb-s8 font-sans2 text-h5;
  }

  h6 {
    @apply block mb-s8 font-sans2 text-h6;
  }

  h1:not(:first-of-type),
  h2:not(:first-of-type),
  h3:not(:first-of-type),
  h4:not(:first-of-type),
  h5:not(:first-of-type),
  h6:not(:first-of-type) {
    @apply mt-s24;
  }

  blockquote {
    @apply my-s40 ml-s24 border-l border-primary px-s24 text-silver text-h5 font-bold lg:ml-s40;
  }
}

.wysiwyg-dark {
  @apply text-white;

  a {
    @apply text-white underline;
  }
}

.wysiwyg-plan {
  ul,
  ol {
    @apply columns-2;

    &:has(li:nth-child(n + 16)) {
      @apply lg:columns-4;
    }
  }
}

.wysiwyg-footer {
  p,
  ul {
    @apply mb-s16;
  }

  ul li {
    @apply ml-s32;

    &::before {
      content: '';
      display: none;
    }
  }
}

.wysiwyg-content-block {
  > ul,
  > ul ul {
    @apply block list-disc pl-s24 text-md mb-s24;
  }

  ul li {
    @apply list-item;

    &::before {
      @apply content-none;
    }
  }
}

.loader {
  @apply border-4 border-white border-t-4 border-t-primary rounded-full w-s32 h-s32 mx-auto animate-spin;
}

.clip-pl {
  clip-path: ellipse(65% 98% at 50% 98%);
}

.clip-pl1 {
  clip-path: ellipse(65% 98% at 50% 0%);
}

.clip-pl2 {
  clip-path: ellipse(100% 55% at 50% 55%);
}

.clip-pl3 {
  clip-path: ellipse(65% 100% at 50% 0%);
}

.clip-pl4 {
  clip-path: ellipse(100% 50% at 50% 43%);
}

.clip-pl5 {
  clip-path: ellipse(190% 55% at 50% 40%);
}

.sermon-template-default {
  .CTABlock {
    @apply bg-light-orange;
  }
}
