.HeroVideo {
  .hero-iframe-wrapper {
    @apply transition-opacity duration-300;

    &[data-play-status='ready'],
    &[data-play-status='paused'],
    &[data-play-status='loading'] {
      @apply opacity-0;
    }

    &[data-play-status='playing'] {
      @apply opacity-100;
    }
  }
}
