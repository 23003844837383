.Navigation {
  &.has-scrolled {
    @apply bg-white duration-300;
  }

  .js-nav-menu li[data-active='true'] {
    @apply before:scale-x-100 before:opacity-100;
  }

  .js-menu[data-menu-active='true'] {
    @apply block;
  }
}
