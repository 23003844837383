/* stylelint-disable selector-max-compound-selectors */

.wpcf7-form {
  @apply w-full;

  fieldset {
    @apply flex flex-col lg:flex-row flex-auto gap-x-s24 gap-y-s24 mb-s24;

    > * {
      @apply w-full;
    }
  }

  br {
    @apply hidden;
  }

  label.no-label {
    @apply hidden;
  }

  span {
    @apply block text-center text-sm text-dark-grey;

    &.checkbox-title {
      @apply text-left text-md text-black font-semibold mb-s16;
    }
  }

  input,
  textarea {
    @apply font-sans text-base w-full border border-light-grey bg-white text-dark-grey p-s12 placeholder:text-dark-grey rounded-br8;
  }

  textarea {
    @apply max-h-[120px];
  }

  input[type='submit'] {
    @apply block border-transparent bg-primary text-white font-semibold mt-s16 w-full px-s32 duration-300 hover:bg-charcoal hover:text-white hover:border-transparent cursor-pointer;
  }

  input[type='checkbox'] {
    accent-color: #f56a1f;
    width: 16px;
    height: 16px;
  }

  .wpcf7-checkbox {
    @apply space-y-s8 max-w-[440px];

    .wpcf7-list-item {
      @apply mx-0;

      label {
        @apply flex items-start gap-x-s12;
      }

      .wpcf7-list-item-label {
        @apply text-sm text-black text-left basis-[90%];
      }
    }
  }

  .wpcf7-not-valid-tip {
    @apply text-red-500 text-left text-sm mt-s8;
  }

  &.invalid .wpcf7-response-output,
  &.unaccepted .wpcf7-response-output {
    @apply text-red-500 border-primary;
  }
}

.plan-your-visit-form {
  .wpcf7-form {
    input[type='submit'] {
      @apply mt-s32;
    }
  }

  .disclaimer {
    @apply mt-s8;
  }
}
