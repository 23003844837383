@layer utilities {
  .btn {
    @apply inline-block text-md font-medium border px-s32 py-s12 rounded-br8 duration-300;
  }

  .btn-primary {
    @apply border-transparent bg-primary text-white hover:bg-charcoal hover:text-white;
  }

  .btn-outline {
    @apply border-primary text-primary hover:bg-peach hover:border-peach hover:text-white;
  }

  .btn-outline-white {
    @apply border-white text-white hover:bg-light-orange hover:text-charcoal;
  }

  .btn-white {
    @apply border-transparent text-primary bg-white hover:bg-charcoal hover:text-white;
  }

  .btn-outline-icon {
    @apply inline-flex border-primary text-primary flex gap-3 items-center px-5;
    span {
      @apply m-0;
    }
  }
}

.btn-livestream {
  @apply btn;

  &[data-is-active='true'] {
    @apply btn-primary;
  }

  @apply btn-white;
}
